import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { LargePost } from "src/components/CommunityStoriesList";
import EmailForm from "src/components/EmailForm";
import Paragraph from "src/components/Paragraph";
import { BLOG_WIDTH } from "constants";
import posts from "posts";
import * as React from "react";
import { Helmet } from "react-helmet";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import Layout from "src/components/Layout";
import img from "src/images/community/community.jpg";

export default function CommunityStoriesPage() {
  return (
    <Layout>
      <Helmet>
        <title>Community Stories</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle="Community Stories"
        title="Updates From Our Community"
        img={img}
        maxTitleWidth={"12em"}
        backgroundPosition="50% 25%"
        containerProps={{ sx: { maxWidth: BLOG_WIDTH } }}
      />
      <Container sx={{ maxWidth: BLOG_WIDTH }}>
        <Paragraph>
          The work and impact of EOCP can be felt through the stories of our
          people: Our team, our residents, and others who make up our community.
        </Paragraph>{" "}
        <Paragraph>
          Interested in receiving future stories and news from EOCP?{" "}
          <b>Sign up for our email newsletter.</b>
        </Paragraph>
        <EmailForm hideTitle />
        <Divider sx={{ mt: 4 }} />
        <Box pt={4}>
          {posts.map((post) => {
            return <LargePost post={post} key={post.slug} />;
          })}
        </Box>
      </Container>
    </Layout>
  );
}
